import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';

import './styles.css';
import {makeStyles, useTheme, Typography, Link, Button, CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { login, userValidate } from '../../actions/userActions';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';

import Images from '../../assets/imgs/index';
import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';

import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import background from "../../assets/imgs/Login_Screen.png";

const useStyles = makeStyles(theme => ({
  signinContainer: {
    width: '100%',
    maxWidth: 360,
    margin: 'auto',
    overflow: 'auto',
    padding: 20
  },
  form: {
    marginBottom: 10
  },
  footer: {
    marginTop: 40
  },
  logo: {
    width: '50%',
    height: 'auto',
    marginBottom: 20
  },
  verifyingLoader: {
    width: '80%',
    maxWidth: 600,
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))


const Signin = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();

  const [form, setForm] = useState({
    code: ''
  })

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'code',
      display_name: t('MARINA_CODE'),
      required: true
    }
  ]));

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    setWaiting((props.user.status === 'loading'));
  }, [props.user])

  useEffect(() => {
    props.userValidate();
  }, [])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value.toUpperCase();
    setForm(newForm);
  }

  const handleSubmit = () => {
    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid)
      props.login(form);
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter')
      handleSubmit();
  }


  return (
    <div className="container" style={{backgroundImage: `url(${background})` }}>
        
      {props.user.verifying && <div className={classes.verifyingLoader}><CircularProgress color="primary" /></div>}

      {/* The actual content */}
      {!props.user.verifying && 
      <div className="inner">


        <div className={classes.signinContainer}>
          
          <div style={{flex: 1}}></div>
          
          {/* Sign in form */}
          <div style={{paddingTop: 20, paddingBottom: 20}}>

            
            <React.Fragment>

              <Typography variant="h5" style={{marginBottom: 20, fontWeight: 300, display: 'flex', flexDirection: 'row', alignItems: 'center'}}><AllInclusiveIcon color="primary" style={{fontSize: 40, marginRight: 10}} />Customer<span style={{fontWeight: 600, marginLeft: 5}}>Dock</span></Typography>

              {props.user.msg && <Alert severity='error'>{t(props.user.msg)}</Alert>}

              <div className={classes.form}>
                <Typography variant="body2">{t('ENTER_YOUR_LINK_CODE')}</Typography>
                <InputField 
                  name='code' 
                  size='large' 
                  validation={validation.code} 
                  value={form.code} 
                  handleChange={handleInputChange} 
                  handleKeyDown={handleKeyDown}
                  noGutters={true} 
                  size="large" 
                  align="center" 
                  noLabel={true}
                  beefed={true}
                  />
              </div>

              <div className={classes.footer}>
                <div className="flex-row gap-30">
                  <ProgressButton variant="contained" size="large" color="primary" fullWidth waiting={waiting} onClick={handleSubmit} gutterBottom={true}>{t('SIGN_IN')}</ProgressButton>
                </div>
              </div>

              

              <div className="flex-col-end p-t gap-0">
                <Typography variant="caption">Powered by</Typography>
                <img src={Images.logo_with_sub} className={classes.logo} alt="BerthVend Smart Solutions" />
              </div>
            </React.Fragment>

          </div>

          <div style={{flex: 1}}></div>
        </div>

      </div>}

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (fields) => {
            dispatch(login(fields));
        },
        userValidate: () => {
            dispatch(userValidate());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
