import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';

import { connect } from 'react-redux';
import { logout, userValidate } from '../actions/userActions';
import { servicesLoad, serviceAdd, serviceUpdate, servicesLoadQuietly } from '../actions/serviceActions';
import GlobalContext from './globalContext';
import MainToolbar from '../components/mainToolbar';

import { makeStyles, useTheme, Tabs, Tab, AppBar, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';
import { useHistory, useLocation } from 'react-router-dom';

const routes = ['/'];

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    alignItems: 'center',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  inner: {
    width: '96%',
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: 20
  },
  drawer: {
    width: '100%',
    maxWidth: 260,
    backgroundColor: theme.palette.grey[700]
  },
  drawerInner: {
    padding: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.getContrastText(theme.palette.grey[700])
  },
  tabs: {
    top: 'auto',
    bottom: 0,
    borderTop: `1px solid ${theme.palette.grey[200]}`
  },
  tab: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
}))


//
//  Used for the silent refresh of the token
//
var silent_refresh;
var services_refresh;
const SOCKET_ENDPOINT = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/v1.0' : '/v1.0';
var SOCKET;

const Main = props => {

  //
  //  Some main structural pieces
  //
  const theme = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [ socketConnected, setSocketConnected ] = useState(false);
  const version = process.env.REACT_APP_VERSION || '0.0.0';


  //
  //  Basic functionality
  //
  const [panel, setPanel] = useState(null);
  const [tab, setTab] = useState(routes.indexOf(location.pathname) || 0);





  //
  //  Basic functionality methods
  //


  const handleChangeTab = (event, newValue) => {
    setTab(newValue);

    if(typeof history !== 'undefined')
      history.push(routes[newValue])
  };




  //
  //  On user events
  //

  useEffect(() => {    
    props.servicesLoad();  
  }, [])
  

  //
  //  Silent user refresh 
  //
  useEffect(() => {
    if(typeof silent_refresh != 'undefined')
      clearTimeout(silent_refresh);

    if(props.user.status === 'in')
      silent_refresh = setTimeout(props.userValidate, Math.floor((props.user.profile.expires*.9)*1000));
  }, [props.user])


  //
  // Services and wallet refresh
  //
  useEffect(() => {
    
    props.userValidate()


    if(typeof services_refresh != 'undefined')
      clearTimeout(services_refresh);

    if(props.user.status === 'in')
      services_refresh = setTimeout(props.servicesLoadQuietly, 15000);

  }, [props.services])




  const _socketListeners = (forceNew = false) => {

    if(forceNew && typeof SOCKET != 'undefined' && SOCKET !== null){
      SOCKET.close();
      SOCKET = null;
    }


    if(!SOCKET)
      SOCKET = io(SOCKET_ENDPOINT, {query: {token: props.user.profile.token}});

    if(typeof SOCKET !== 'undefined' && SOCKET !== null){

      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$connect'] === 'undefined')
        SOCKET.on('connect', () => {
          _joinRoom();
          setSocketConnected(true);
        })

      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$join'] === 'undefined')
        SOCKET.on('join', () => {
          _joinRoom();
        })
    
      if(typeof SOCKET._callbacks['$message'] == 'undefined')
        SOCKET.on('message', (message) => {
          //_handleIncommingMessage(message);
        })
  
  
      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$disconnect'] === 'undefined')
        SOCKET.on('disconnect', () => {
          setSocketConnected(false);
        })

    }

  }

  const _joinRoom = () => {
    if(typeof SOCKET !== 'undefined' && SOCKET !== null && props.profile.status === 'OK' && typeof props.profile.data.id_organisation !== 'undefined')
      SOCKET.emit('join', props.profile.data.id_organisation)
  }


  const getPanelTitle = () => {

    if(panel === null)
      return '';

    switch(panel.toUpperCase()){
      case 'ABOUT':
        return t('ABOUT_BERTHVEND');
      case 'HELP':
        return t('HELP');
      case 'FEEDBACK':
        return t('SEND_FEEDBACK');
    }
  }
  

  return (
    <GlobalContext.Provider value={{
      ...props,
      version: version,
      socketConnected: socketConnected
    }}>
      {/* Routing compnents */}
      <div className={classes.container}>
        <MainToolbar handleLogout={props.logout} />

        <div className={classes.inner}>
          {props.children}
        </div>

      </div>
    </GlobalContext.Provider>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.user,
    services: state.services
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    userValidate: () => {
      dispatch(userValidate());
    },
    servicesLoad: () => {
      dispatch(servicesLoad());
    },
    serviceUpdate: (payload) => {
      dispatch(serviceUpdate(payload));
    },
    servicesLoadQuietly: () => {
      dispatch(servicesLoadQuietly());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);