import React, {useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Table, TableBody, TableRow, TableCell, IconButton, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import API from '../global/api';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import { localeCurrency } from '../global/helpers';
import images from '../assets/imgs';
import RefreshIcon from '@material-ui/icons/Refresh';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
    noServices: {
        marginTop: 10,
        backgroundColor: '#ffffff',
        padding: '8px 14px',
        borderRadius: 12,
        color: theme.palette.grey[600],
        textAlign: 'center'
    },
    skeleton: {
        height: 14,
        borderRadius: 12
    },
}));

const RecentServices = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [data, setData] = useState([]);


    const loadServices = async () => {
        setWaiting(true);
        setError(null);

        try {

            const fetchServices = await API.req('services/recent', 'GET');
            if(fetchServices.success)
                setData(fetchServices.result);
            else
                throw fetchServices.msg;

            
        } catch (error) {
            setError(error);
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        loadServices()
    }, [])


    const getMeterDisplay = service => {
        switch(parseInt(service.id_unit)){
            case 1:
            case 2:
                return (parseFloat(service.meter)/1000).toFixed(2).toString()
            default:
                return parseFloat(service.meter).toFixed(2).toString()
        }
    }


    return (
        <React.Fragment>
            <div className="p-b">
                <div className="flex-row align-center" style={{marginBottom: 10}}>
                    <Typography variant="subtitle2">{t('RECENT_SERVICES')}</Typography>
                    <IconButton onClick={() => {if(!waiting) loadServices()}}>{!waiting ? <RefreshIcon color="primary" style={{fontSize: 18}} /> : <CircularProgress size={18} />}</IconButton>
                </div>


                {waiting && 
                    <Table size="small">
                        <TableBody>
                            {[1,2,3,4,5].map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 14}} /></TableCell>
                                    <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 70}} /></TableCell>
                                    <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 90}} /></TableCell>
                                    <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 60}} /></TableCell>
                                    <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 50}} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
                


                {(!waiting && data.length === 0) && 
                    <div className={classes.noServices}>
                        <Typography variant="body2">{t('NO_RECENT_SERVICES')}</Typography>
                    </div>
                }


                {!waiting &&
                    <Table size="small">
                        <TableBody>
                            {data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell><img src={row.id_service_type === 2 ? images.Icons_Water_Icon : images.Icons_Electric_Icon} style={{height: 16, width: 'auto'}} alt="Utility type" /></TableCell>
                                    <TableCell><Typography variant="caption">{SQLtoUTCDate(row.start_time, false)}</Typography></TableCell>
                                    <TableCell style={{flex: 1}}><Typography variant="caption">{row.identity}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="caption">{getMeterDisplay(row)} {row.unit}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="caption"><b>{localeCurrency(row.cost, row.currency)}</b></Typography></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }

            </div>
        </React.Fragment>
    )
}
export default RecentServices;