import React from 'react';
import {AppBar, IconButton, makeStyles, Toolbar, useTheme, Typography} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

const useStyles = makeStyles(theme => ({
    menuButton: {

    },
    logo: {
        height: 32,
        width: 'auto'
    }
}))


const MainToolbar = props => {

    const theme = useTheme();
    const classes = useStyles(theme);

    const handleLogout = () => {
        if(typeof props.handleLogout === 'function')
            props.handleLogout();
    }

    return (
        <AppBar position="sticky" color="default" elevation={0} style={{zIndex: 899}}>
            <Toolbar>
            <Typography variant="h5" style={{marginBottom: 0, fontSize: '1rem', fontWeight: 300, display: 'flex', flexDirection: 'row', alignItems: 'center'}}><AllInclusiveIcon color="primary" style={{fontSize: 26, marginRight: 6}} />Customer<span style={{fontWeight: 600, marginLeft: 3}}>Dock</span></Typography>
                <div className="flex"></div>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleLogout}>
                    <ExitToAppIcon color="primary"/>
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default MainToolbar