import store from './store';
const {request} = require('axios'); 

class API {
    //static ENDPOINT = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/v1.0/customer_client/' : process.env.REACT_APP_ENDPOINT;
    static ENDPOINT = process.env.REACT_APP_ENDPOINT;

    /**
     * 
     * @param {*} uri The path to be added to the end of your endpoint e.g. /login
     * @param {*} method GET | POST | PUT | DELETE
     * @param {*} body The params to be passed either in the body of a POST or PUT or as the params of a GET
     * @param {*} headers Any custom headers to be sent
     * @returns Promise with the response from the server
     */
    static req = (uri = null, method = null, body = null, headers = {}) => {

        //
        //  Build the headers
        //
        let reqHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers
        };

        const state = store.getState();
        if(state.user.status === 'in' && state.user.profile.token !== null)
            reqHeaders['x-access-token'] = state.user.profile.token;

        /*
        * GAXIOS
        */
        return new Promise((resolve, reject) => {
            request({
                url: this.ENDPOINT + uri,
                method: method,
                headers: reqHeaders,
                data: method !== 'GET' ? body : null,
                params: method === 'GET' ? body : null,
                timeout: 15000,
                withCredentials: true
            })
            .then(res => {
                if(res.status === 200 && res.data.success === true)
                    resolve(res.data);
                else
                    reject(typeof res.data.msg !== 'undefined' ? res.data.msg : res);
            })
            .catch(err => {
                reject(typeof err.msg !== 'undefined' ? err.msg : err);
            })
        });

    }

    static addToLocalArray = async (storage_key, val) => {

        const local_array = localStorage.getItem(storage_key);
        let new_array = local_array !== null ? JSON.parse(local_array) : [];
        new_array.push(val);
        localStorage.setItem(storage_key, JSON.stringify(new_array));
        return {
            success: true,
            result: val
        };

    }

    static getLocal = async (storage_key) => {

        const local_array = localStorage.getItem(storage_key);
        return local_array !== null ? JSON.parse(local_array) : null;

    }

    static setLocal = async (storage_key, val) => {
        
        localStorage.setItem(storage_key, JSON.stringify(val));

    }

}
export default API