import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core';

import API from './global/api';

import { userValidate } from './actions/userActions';

import Signin from './context/signin/signin';
import Main from './context/main';

import Home from './routes/home';
import NotFound from './routes/notFound';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    position: 'relative'
  }
}))



const App = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  

  return (
          
    <Router>
      <div className={classes.root}>

        {props.user.status !== 'in' && 
        
          <Switch>
            <Route component={Signin} />
          </Switch>
        
        }

        {props.user.status === 'in' &&
        
            <Main history={props.history}>

              <Switch>
                  <Route path={"/"} exact component={Home} ></Route>
                  <Route component={NotFound} />
              </Switch>

            </Main>
        }
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userValidate: () => {
      dispatch(userValidate());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
