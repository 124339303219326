import React, {useContext, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core';
import globalContext from '../context/globalContext';
import { useTranslation } from 'react-i18next';
import ProfileBalance from '../content/profileBalance';
import CurrentServices from '../content/currentServices';
import RecentServices from '../content/recentServices';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  inner: {
    padding: 20,
    flex: 1
  }
}))

const Home = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);
  const {t} = useTranslation();

  const [balanceTab, setBalanceTab] = useState(0);


  return (

    <React.Fragment>
      <div className={classes.container}>

        <div className={classes.inner}>

            <ProfileBalance />

            <CurrentServices />

            <RecentServices />

        </div>

      </div>

    </React.Fragment>
  );

}

export default Home;
