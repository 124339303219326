import Api from '../global/api';

export function servicesLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'SERVICES_LOAD',
            status: 'LOADING',
            payload: null
        })


        Api.req(`services`, 'GET').then(
            res => {

                dispatch({
                    type: 'SERVICES_LOAD',
                    status: 'OK',
                    payload: res.result
                })

            },
            err => {
                // Login error
                dispatch({
                    type: 'SERVICES_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your services'
                })
            }
        )

    }
}

export function serviceAdd (payload) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'SERVICE_ADD',
            status: 'OK',
            payload: payload
        })

    }
}

export function serviceUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'SERVICE_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}

export function servicesLoadQuietly () {
    return async dispatch => {

        Api.req(`services`, 'GET').then(
            res => {

                dispatch({
                    type: 'SERVICES_LOAD',
                    status: 'OK',
                    payload: res.result
                })

            },
            err => {
                // Login error
                dispatch({
                    type: 'SERVICES_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your services'
                })
            }
        )

    }
}