import React, {useContext, useEffect, useState} from 'react';
import { useTheme, Typography, makeStyles, CircularProgress} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import globalContext from '../context/globalContext';
import { localeCurrency } from '../global/helpers';
import API from '../global/api';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
    balance: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 40
    },
    balance_title: {
      marginBottom: 15,
      fontWeight: 400
    },
    balance_subtitle: {
      marginBottom: 5,
      fontWeight: 400
    },
    balance_amount: {
      fontWeight: 600,
      marginBottom: 0,
      lineHeight: 1
    },
    balance_currency: {
      fontWeight: 400,
      marginBottom: 0,
      lineHeight: 1.8
    },
    smallIcon: {
      height: 20,
      width: 20
    },
    logo: {
      width: '70%',
      maxWidth: 200,
      height: 'auto',
      marginBottom: 20,
      marginTop: 20
    },
    logoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    }
}));

let direction = 'left';

const ProfileBalance = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    const context = useContext(globalContext);
    
    const [logo, setLogo] = useState(null);
    
    useEffect(() => {
      API.req('user/logo', 'GET').then(
        res => {
          if(res.success === true)
            setLogo(res.result);
        }, err => console.log(err)
      )
    }, [])

    return (
      <React.Fragment>
        <div className={classes.balance}>
            
            {logo !== null && <div className={classes.logoWrapper}><img src={logo} className={classes.logo} alt={context.user.profile.user.organisation_name} /></div>}

            <Typography variant="subtitle2" className={classes.balance_subtitle}>{t('YOUR_CURRENT_MARINA_BALANCE_IS', {marina: context.user.profile.user.organisation_name})}</Typography>
            <Typography variant="h2" className={classes.balance_amount}>{context.user.status === 'LOADING' ? <CircularProgress size={24} />: localeCurrency(context.user.profile.user.balance, context.user.profile.user.currency)}</Typography>
            {context.user.status !== 'LOADING' && <Typography variant="subtitle2" className={classes.balance_currency}>{context.user.profile.user.customer_account_type}: ({context.user.profile.user.currency})</Typography>}
            {context.user.status !== 'LOADING' && <Typography variant="subtitle2" className={classes.balance_currency}>{t('BOAT_NAME')}: {context.user.profile.user.boat_name}</Typography>}
            

        </div>
      </React.Fragment>
    )
}
export default ProfileBalance;