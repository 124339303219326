import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import user from "../reducers/userReducer";
import services from "../reducers/servicesReducer";

export default createStore(
    combineReducers({
        user,
        services
    }),
    {},
    applyMiddleware(thunk)
)